import React, { useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import "../home/Home.css"
import eventTravelContent from "./services.json"
import MediaQuery from "react-responsive"

function EventTravel() {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div>
			<MediaQuery minDeviceWidth={426}>
				<img style={{ width: "100%", objectFit: "fill" }} src="../assets/event-travel/1.png" alt="Banner of Strategic Management" />
			</MediaQuery>
			<MediaQuery maxDeviceWidth={425}>
				<img
					style={{ width: "100%", objectFit: "fill", height: "25em" }}
					src="../assets/event-travel/mob-1.png"
					alt="Banner of Strategic Management"
				/>
			</MediaQuery>

			<div className="main-wrapper">
				{/* SECTION 1 */}
				<div className="pd-tb-40">
					<Row>
						<Col md={4} className="">
							<h2 className="para-title">Event Travel Management by GITCO</h2>
						</Col>
						<Col md={8}>
							<div>
								<h5 className="para-subtitle">
									At The Great Indian Travel Co. (GITCO), we believe in the power of experiences that engage, excite and inspire people to perform. We
									call it ‘Connected Experiences’.
								</h5>

								<p>
									Our unique blend of creativity, customer service and innovative event technology drives transformative corporate event experiences
									which deliver on your business’s strategic objectives.
								</p>

								<p>
									From unique destinations and event experiences, to engaging event technology, intuitive reporting and robust supplier negotiations,
									our expert teams can tailor an event solution to fit every budget and business need.
								</p>
							</div>
						</Col>
					</Row>
				</div>

				{/* SECTION 2 */}
				{/* <div className="pd-tb-50">
					<h2 className="para-title">Event management experts</h2>
					<div className="pd-t-30">
						<ReactPlayer width="100%" height="30em" controls url="https://www.youtube.com/watch?v=s4u3RCrzJCc" />
					</div>
				</div> */}

				{/* SECTION 3 */}
				<div className="pd-tb-50">
					<h2 className="para-title">Maximising your events and travel budget</h2>
					<div className="pd-t-30">
						<h6>
							As part of The Great Indian Travel Co. (GITCO) group, Event Management can consolidate and manage every aspect of your travel and events
							program, from supplier expenditure and preferences to technology platforms and communications – driving greater cost savings, improving
							efficiencies and maximising your return on investment.
						</h6>

						<p className="pd-t-10">
							By consolidating your corporate travel and events expenditure, your business will enjoy enhanced supplier negotiation power on your
							preferred travel services, delivering extra value and savings to your budget.
						</p>

						<p className="pd-t-10">
							We deliver value to every aspect of your event and travel program, from engaging event experiences to strategic advice, global buying
							power, 24/7 local support.
						</p>
					</div>
				</div>

				{/* SECTION 4 */}
				<div className="">
					{eventTravelContent.map((content, i) => (
						<Row key={i} className={i % 2 === 0 ? "pd-tb-50 section-content-im" : "pd-tb-50"}>
							<Col xs={12} md={6} className="pd-tb-20">
								<div className="pd-lr-20">
									<h2 className="section-title">{content.title}</h2>

									<p className="pd-t-20">{content.description}</p>
									{content.description2 && <p className="pd-t-20">{content.description2}</p>}

									<h6 className="section-title pd-tb-10">{content.subTitle}</h6>

									<ul className="points">
										{Object.keys(content.steps).map((key, index) =>
											index > 1 ? (
												<li key={index} className={`more-steps-${index}`}>
													{content.steps[key]}
												</li>
											) : (
												<li key={index}>{content.steps[key]}</li>
											)
										)}
									</ul>
								</div>
								{/* <button className="read" id={`more-btn-${i}`} onClick={() => readMore()}>
									Read More <i className="fas fa-angle-down"></i>
								</button> */}
							</Col>
							<Col xs={12} md={6} className="pd-tb-20">
								<img style={{ width: "100%" }} src={window.screen.width > 425 ? content.imgUrl : content.imgUrlMob} alt={content.name} />
							</Col>
						</Row>
					))}
				</div>

				{/* SECTION 5 */}
				<div className="pd-tb-40">
					<Row>
						<h2 className="para-title">Event support services</h2>
						<div>
							<h5 className="para-subtitle pd-t-30 fw-600">On-site support</h5>

							<p>GITCO event specialists are available onsite 24/7 to assist with delegate requests or event management needs as they arise.</p>

							<h5 className="para-subtitle pd-t-10 fw-600">Event logistics</h5>

							<p>
								GITCO’s end-to-end event logistics ensure the successful planning, co-ordination and delivery of a memorable and engaging event
								experience.
							</p>

							<h5 className="para-subtitle pd-t-10 fw-600">Venue sourcing</h5>

							<p>
								At GITCO, we’re at the cutting edge of travel technology and service delivery. With innovative systems and solutions, our strategic
								account managers gather valuable insights for identifying opportunities to reduce costs, manage compliance and mitigate risk. Working
								on your behalf, our team continues to monitor, develop and refine your travel management program to make travel simpler for you, and
								deliver a measurable return on your investment.
							</p>

							<h5 className="para-subtitle pd-t-10 fw-600">We provide global support</h5>

							<p>
								GITCO can save you significant time and money when sourcing your next meeting or conference venue. Our team can locate the most
								suitable and cost-effective venues for events of all sizes, by conducting extensive destination research and leveraging sophisticated
								inventory management tools to identify the best rates and availability.
							</p>

							<h5 className="para-subtitle pd-t-10 fw-600">Activities & social programs</h5>

							<p>
								GITCO can incorporate a wide range of activities and social initiatives into your event programs, tailored to suit your theme, budget
								and objectives and delivering maximum engagement.
							</p>

							<h5 className="para-subtitle pd-t-10 fw-600">Production management</h5>

							<p>
								Our creative production team will deliver strategic event theming, communications and promotions that connect people with your brand
								and event objectives.
							</p>

							<h5 className="para-subtitle pd-t-10 fw-600">Creative services</h5>

							<p>
								GITCO can incorporate a wide range of activities and social initiatives into your event programs, tailored to suit your theme, budget
								and objectives and delivering maximum engagement.
							</p>
							<h5 className="para-subtitle pd-t-10 fw-600">We provide global support</h5>

							<p>
								GITCO can save you significant time and money when sourcing your next meeting or conference venue. Our team can locate the most
								suitable and cost-effective venues for events of all sizes, by conducting extensive destination research and leveraging sophisticated
								inventory management tools to identify the best rates and availability.
							</p>

							<h5 className="para-subtitle pd-t-10 fw-600">Activities & social programs</h5>

							<p>
								GITCO can incorporate a wide range of activities and social initiatives into your event programs, tailored to suit your theme, budget
								and objectives and delivering maximum engagement.
							</p>
						</div>
					</Row>
				</div>
			</div>
		</div>
	)
}

export default EventTravel
