import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Modal, Carousel } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import codes from "country-calling-code";
import hotelList from "./hotel-list.json";
import "./WhefHotelList.css";

const WhefHotelList = () => {
	const [show, setShow] = useState(false);
	const [fields, setFields] = useState({
		fullName: "",
		email: "",
		contactNumber: "",
		countryCode: "",
		country: "",
		checkInDate: null,
		checkOutDate: null,
		roomsCount: 0,
		guestsCount: 0,
		occupancyType: "",
		roomType: {},
		addOns: []
	});
	const [loading, setLoading] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [selectedHotel, setSelectedHotel] = useState({});
	const [roomCategory, setRoomCategory] = useState();
	const [selectedRoomIndex, setSelectedRoomIndex] = useState();
	const [isSelectedRoom, setIsSelectedRoom] = useState();
	const [selectedPrice, setSelectedPrice] = useState();
	const [selectedAddOns, setSelectedAddOns] = useState({
		airportPickup: false,
		airportDrop: false,
		flightTickets: false,
	});
	const { allCountries, countryCode } = fields;

	useEffect(() => {
		setFields({
			...fields,
			allCountries: codes
		});
	}, [])

	const handleChange = (name) => (event) => {
		if (name === "country") {
			const selectedCountry = allCountries.find((element) => element.country === event.target.value)
			setFields({
				...fields,
				country: selectedCountry?.country,
				countryCode: selectedCountry?.countryCodes[0]
			})
		} else if (name === "contactNumber") {
			setFields({
				...fields,
				contactNumber: (countryCode) + "-" + event.target.value
			})
		} else if (name === "checkInDate") {
			setFields({
				...fields,
				[name]: moment(event.target.value).format('DD-MM-YYYY')
			})
		} else if (name === "checkOutDate") {
			setFields({
				...fields,
				[name]: moment(event.target.value).format('DD-MM-YYYY')
			})
		} else {
			setFields({
				...fields,
				[name]: event.target.value
			})
		}
	}

	// Handle checkbox change
	const handleCheckboxChange = (e) => {
		const { name, checked } = e.target;
		setSelectedAddOns({
		  ...selectedAddOns,
		  [name]: checked, // update the state based on checkbox name
		});
	};

	// Handle room type change
	const handleRoomChange = (type, option, i, id) => {
		setFields({
			...fields,
            occupancyType: type,
			roomType: option
		})
		setIsSelectedRoom(i);
		setSelectedRoomIndex(id);
	};

	// Handle price type change
	// const handlePriceChange = (event) => {
	// 	setSelectedPrice(event.target.value);
	// 	const selectedAmount = parseInt(event.target.value);
    // 	const selectedObj = selectedRoomData.options.find(option => option.amount === selectedAmount);
	// 	setFields({
	// 		...fields,
    //         roomType: selectedObj
	// 	})
	// };

	// const selectedRoomData = roomCategory?.find(room => room.type === selectedRoom);

	const sendEmail = async (obj) => {
		const sendinblueApiKey = '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'; // Get this from Sendinblue dashboard
		const templateId = 2; // Replace with your template ID
	 
		try {
		  const response = await axios.post(
			'https://api.sendinblue.com/v3/smtp/email',
			{
			  sender: { email: 'alokyadav495@gmail.com', name: 'The GITCO' },
			  to: [{ email: 'whefmumbai@thegitco.com', name: 'The GITCO' }, { email: obj.email, name: obj.fullName }],
			  templateId: templateId, // Sendinblue email template ID
			  params: {...obj},
			},
			{
			  headers: {
				'api-key': sendinblueApiKey,
				'Content-Type': 'application/json',
			  },
			}
		  );
	 
		  if (response.status === 201) {
			alert('Email sent successfully!');
			setShow(false);
		  } else {
			alert('Failed to send email');
		  }
		} catch (error) {
		  console.error('Error sending email:', error);
		  alert('An error occurred while sending the email');
		}
	  };

	const submitForm = (event) => {
		event.preventDefault();
		if (isSubmit) {
			const selected = Object.keys(selectedAddOns).filter(
				(option) => selectedAddOns[option]
			);
			setFields({...fields});
			let obj = {
				...fields,
				hotelName: selectedHotel.name,
				addOns: selected.join(', ')
			}
			delete obj.allCountries;
			sendEmail(obj);
			setIsSubmit(false);
		}
	}

	const handleRequest = (hotel) => {
		setShow(true);
		setSelectedHotel(hotel);
		setRoomCategory(hotel.roomCategory);
	}

	const handleClose = () => {
		setShow(false);
		setRoomCategory();
		setSelectedRoomIndex();
		setIsSelectedRoom();
	};

	return (
		<section className="hotel-wrapper">
			<div className="pd-t-40">
				<h2>Showing Properties</h2>

				<Row className="pd-tb-40">
					{hotelList && hotelList.map((hotel, i) => (
						<Col xs={12} md={4} sm={6} key={i} className="mb-4">
							<Card className="hotel-card">
								<Carousel>
								{
									hotel.photos && hotel.photos.map((photo, i) => (
										<Carousel.Item key={i}>
											<Card.Img className="hotel-feature"
												variant="top"
												src={photo}
											/>
										</Carousel.Item>
									))
								}
								</Carousel>
								
								<Card.Body className="pd-15">
									<Card.Title>
										<h6 className="hotel-name">{hotel.name}</h6>
									</Card.Title>
									<Card.Text className="flex-sb hotel-info">
										<span className="hotel-category">{hotel.category}</span>
										<div>
											{(
												<span className="hotel-amount">
													{hotel.roomCategory[0].options[0].amount ? "₹"+hotel.roomCategory[0].options[0].amount : 'On Request'}
												</span>
											)}
										</div>
									</Card.Text>
									<p className="tax">(excluding Taxes)</p>
									<Card.Text className="hotel-distance">
										<p>Jio World Convention Centre: <span className="distance">{hotel.distanceToJioGarden} kms</span></p>
									</Card.Text>
									<div className="hotel-amenities d-flex flex-wrap align-items-center">
										<span className="text-underline amenities-label">Amenities:</span>
										{
											hotel.amenities.map((item, i) => (
												item.value && <span key={i} className="amenities"> &nbsp;{item.name}</span>
											))
										}
									</div>
								</Card.Body>
								<div className="footer p-3">
									{hotel.freeCancellationDate && <p className="cancel-date">Free Cancellation till <i>{hotel.freeCancellationDate}</i></p>}
									<Button className="request-cta w-100" onClick={() => handleRequest(hotel)}>Request</Button>
								</div>
							</Card>
						</Col>
					))}
				</Row>

				<Row className="pd-tb-20">
					<div className="text-center footnote">
						<p className="title-brand">
							The Great Indian Travel Co. (BIGBASH PROJECTS PVT LTD) Official accomodation partner with World Hindu Economic Forum (12th to 15th Dec Jio World Convention Centre, Bharat)
						</p>
						<p>Booking Coordinators :</p>
						<p>Mr. Vikas Mishra : +91 73042-23812</p>
						<p>Mr. Rajeev Kumar : +91 97566-46127</p>
						<p>Mr. Sandeep Pandey : +91 88000-26648</p>
						<p>Email : whefmumbai@thegitco.com</p>
					</div>
				</Row>
			</div>

			{/* Request Form Modal */}
			<Modal show={show} onHide={handleClose} className="pd-15" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title className="headline">{selectedHotel.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="sub-headline">Fill in the Request Form</p>
					{/* FORM */}
					<div className="">
						<form className="contact-form" id="request_form" onSubmit={submitForm}>
							<Row>
								<Col md={6} sm={12}>
									<div className="pd-t-20">
										<p className="field-name">
											<span className="req">* </span>Full Name
										</p>
										<input name="fullName" onChange={handleChange("fullName")} type="text" className="form-input"  />
									</div>
								</Col>
								<Col md={6} sm={12}>
								    <div className="pd-t-20">
										<p className="field-name">
											<span className="req">* </span>Email
										</p>
										<input name="email" onChange={handleChange("email")} type="email" className="form-input"  />
									</div>
								</Col>
							</Row>

							<Row>
								<Col md={6} sm={12}>
									<div className="pd-t-20">
										<p className="field-name">
											<span className="req">* </span>Select Region
										</p>
										<select name="country" onChange={handleChange("country")} className="form-input" >
											<option value="">--Select--</option>
											{allCountries && allCountries.map((item, i) => (
												<option key={i} value={item.country}>
													{item.country}
												</option>
											))}
										</select>
									</div>
								</Col>
								<Col md={6} sm={12}>
									<div className="pd-t-20">
										<p className="field-name">
											<span className="req">* </span>Contact Number(WhatsApp)
										</p>
										<div className="inflex-justify">
											<input
												name="countryCode"
												type="text"
												value={countryCode}
												className="form-input"
												style={{ width: "25%", textAlign: "center" }}
												readOnly
											/>
											<input
												onChange={handleChange("contactNumber")}
												name="contactNumber"
												type="number"
												maxLength="10"
												className="form-input"
												style={{ width: "70%" }}
												
											/>
										</div>
									</div>
								</Col>
							</Row>

							<Row>
								<Col md={6} sm={12}>
									<div className="pd-t-20">
										<p className="field-name">
											<span className="req">* </span>Check-in Date
										</p>
										<input name="checkInDate" onChange={handleChange("checkInDate")} type="date" className="form-input"  />
									</div>
								</Col>
								<Col md={6} sm={12}>
									<div className="pd-t-20">
										<p className="field-name">
											<span className="req">* </span>Check-out Date
										</p>
										<input name="checkOutDate" onChange={handleChange("checkOutDate")} type="date" className="form-input"  />
									</div>
								</Col>
							</Row>

							<Row>
								<Col md={6} sm={12}>
									<div className="pd-t-20">
										<p className="field-name">
											<span className="req">* </span>No. of Rooms
										</p>
										<select name="roomsCount" onChange={handleChange("roomsCount")} className="form-input" >
											<option value="">--Select--</option>
											<option value="1">1</option>
											<option value="2">2</option>
											<option value="3">3</option>
											<option value="4">4</option>
										</select>
									</div>
								</Col>
								<Col md={6} sm={12}>
									<div className="pd-t-20">
										<p className="field-name">
											<span className="req">* </span>No. of Guests
										</p>
										<select name="guestsCount" onChange={handleChange("guestsCount")} className="form-input" >
											<option value="">--Select--</option>
											<option value="1">1</option>
											<option value="2">2</option>
											<option value="3">3</option>
											<option value="4">4</option>
											<option value="5">5</option>
											<option value="6">6</option>
											<option value="7">7</option>
											<option value="8">8</option>
											<option value="9">9</option>
											<option value="10">10</option>
										</select>
									</div>
								</Col>
							</Row>

							<Row>
								<Col>
									<div className="pd-t-20">
										<p className="field-name">
											<span className="req">* </span>Room Category
										</p>
										{roomCategory && roomCategory.map((room, index) => (
											<Card key={index} className={selectedRoomIndex == room.id ? 'selected room-card' : 'room-card'}>
												<Row className="">
													<Col md={5} sm={4} className="room-card-left pd-10">
														<Card.Img className="room-photo"
															variant="top"
															src={room.photo}
														/>
														<p style={{fontWeight: 600, marginBottom: 0}}>{room.type}</p>
													</Col>
													<Col md={7} sm={78} className="room-card-right pd-10">
														{
															room.options.map((item, index) => (
																<div key={index} className={index%2 == 0 ? "options" : ""}>
																	<div className={index%2 != 0 ? "price-details" : ""}>
																		<p>{item.type} - {item.amount ? `₹${item.amount} (GST ${item.gst}%)` : 'Price on Request'}</p>
																		<button className={isSelectedRoom == item.id ? 'select-cta active-cta' : 'select-cta'} type="click" onClick={() => handleRoomChange(room.type, item, item.id, room.id)}>
																			{isSelectedRoom == item.id ? 'Selected' : 'Select Room'}
																		</button>
																	</div>
																</div>
															))
														}
													</Col>
												</Row>
											</Card>
										))}
									</div>
								</Col>
							</Row>

							<Row>
								<Col md={12} sm={12}>
                                    <div className="pd-t-20">
                                        <p className="field-name">
                                            Add Ons:
                                        </p>
                                        <div>
											<label>
												<input
													type="checkbox"
													name="airportPickup"
													checked={selectedAddOns.airportPickup}
													onChange={handleCheckboxChange}
												/>
												&nbsp;Airport Pickup - INR 1500
											</label>
										</div>
										<div>
											<label>
												<input
													type="checkbox"
													name="airportDrop"
													checked={selectedAddOns.airportDrop}
													onChange={handleCheckboxChange}
												/>
												&nbsp;Airport Drop - INR 1000
											</label>
										</div>
										<div>
											<label>
												<input
													type="checkbox"
													name="flightTickets"
													checked={selectedAddOns.flightTickets}
													onChange={handleCheckboxChange}
												/>
												&nbsp;Flight Tickets
											</label>
										</div>
                                    </div>
                                </Col>
							</Row>

							<Row className="pd-t-40">
								<Col className="text-center">
									<button className="cancel" onClick={handleClose}>
										Cancel
									</button>
									<button className="submit-form" type="submit" onClick={() => setIsSubmit(true)}>
										Submit <i className="fas fa-chevron-right"></i>
									</button>
								</Col>
							</Row>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</section>
	);
};

export default WhefHotelList;
