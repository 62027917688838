import React, { useState, useEffect } from "react"
import { Row, Col, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
import "./Home.css"
import elevateContent from "./home.json"
import ContactForm from "../contactForm/ContactForm"
import Carousel from "react-bootstrap/Carousel"
import { CarouselItem } from "react-bootstrap"
import MediaQuery from "react-responsive"
import ReactPlayer from "react-player"

function Home() {
	const [mobileBanner, setMobileBanner] = useState(false)
	const [modalShow, setModalShow] = useState(false)

	// const readMore = (index) => {
	// 	console.log("iinnn", index)
	// 	if (index > 1) {
	// 		var moreText = document.getElementsByClassName(`more-steps-${index}`)
	// 		var btnText = document.getElementsByClassName(`more-btn-${index}`)
	// 		console.log("qaz", moreText, btnText)
	// 	}
	// 	// if (index > 2) {
	// 	// 	// dots.style.display = "inline"
	// 	// 	btnText.innerHTML = "Read more"
	// 	// 	moreText.style.display = "none"
	// 	// } else {
	// 	// 	// dots.style.display = "none"
	// 	// 	btnText.innerHTML = "Read less"
	// 	// 	moreText.style.display = "inline"
	// 	// }
	// }

	const showBanner = () => {
		if (window.innerWidth <= 860) {
			setMobileBanner(false)
		} else {
			setMobileBanner(false)
		}
	}

	const travelPopUp = () => {
		// if (!modalShow) {
		// 	let expirationEpoch = localStorage.getItem("expirationPopUp")
		// 	if (!expirationEpoch) {
		// 		setTimeout(() => {
		// 			setModalShow(true)
		// 		}, 3000)
		// 	} else {
		// 		let date = new Date()
		// 		let dateEpoch = date.getTime()

		// 		if (expirationEpoch < dateEpoch) {
		// 			setModalShow(true)
		// 		}
		// 	}
		// }
	}

	const closeTravelPopup = () => {
		let date = new Date()
		date.setDate(date.getDate() + 2)

		let dateEpoch = date.getTime()

		localStorage.setItem("expirationPopUp", dateEpoch)
		setModalShow(false)
	}

	const MyVerticallyCenteredModal = (props) => {
		return (
			<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header>
					<button className="close-popup" onClick={() => closeTravelPopup()}>
						X
					</button>
				</Modal.Header>
				<Modal.Body>
					<Carousel interval={5000}>
						<CarouselItem>
							<img style={{ width: "100%" }} src="../assets/packages/package-3.jpeg" alt="GITCO Travel Package Banner" />
						</CarouselItem>
						<CarouselItem>
							<img style={{ width: "100%" }} src="../assets/packages/package-2.jpeg" alt="GITCO Travel Package Banner" />
						</CarouselItem>
						<CarouselItem>
							<img style={{ width: "100%" }} src="../assets/packages/package-1.jpeg" alt="GITCO Travel Package Banner" />
						</CarouselItem>
					</Carousel>
				</Modal.Body>
				<Modal.Footer>
					<Link to="/contact">
						<button onClick={props.onHide} className="click-here" style={{ color: "#333652", margin: "10px 0 0 0" }}>
							Click here to Contact
						</button>
					</Link>
				</Modal.Footer>
			</Modal>
		)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		travelPopUp()
		showBanner()
	}, [])

	return (
		<>
			{/* <div>
				{mobileBanner ? (
					<img src="../assets/home/mobile-christmas.gif" alt="Merry Christmas!" style={{ width: "100%" }} />
				) : (
					<img src="../assets/home/christmas.gif" alt="Merry Christmas!" style={{ width: "100%" }} />
				)}
			</div> */}

			{/* SECTION 1 */}
			<div>
				<MediaQuery minDeviceWidth={800}>
					<ReactPlayer width="100%" height="30em" loop={true} playing={true} muted url="../assets/home/hero-video-desktop.mp4" />
				</MediaQuery>
				<MediaQuery maxDeviceWidth={799}>
					<ReactPlayer width="100%" height="15em" loop={true} playing={true} muted url="../assets/home/hero-video-desktop.mp4" />
				</MediaQuery>
			</div>
			<div className="main-wrapper">
				<div className="pd-tb-40">
					<Row>
						<Col md={4} className="vert-al">
							<h2 className="para-title">Welcome to The Great Indian Travel Co.</h2>
						</Col>
						<Col md={8}>
							<div>
								<h5 className="para-subtitle">
									GITCO is a leading player in business travel management services. We drive savings, efficiency and safety to businesses and their
									travellers all around the world.
								</h5>

								<p>
									If you are looking for a tailored travel management solution, delivering customer service excellence and a demonstrable return on
									investment, then you’ve come to the right place.
								</p>
							</div>
						</Col>
					</Row>
				</div>
			</div>

			{/* SECTION 2 */}
			<div className="pd-tb-10">
				<div className="gitco-package-div">
					<h4 className="" style={{ fontWeight: "700", margin: "0" }}>
						GITCO Travel Packages{" "}
						<button onClick={() => setModalShow(true)} className="click-here">
							Click here
						</button>
						<MyVerticallyCenteredModal show={modalShow} />
					</h4>
				</div>
			</div>

			<div className="main-wrapper">
				{/* SECTION 3 */}
				<div className="pd-tb-50">
					<h2 className="para-title">Elevate your travel program with GITCO</h2>
					<div className="pd-t-30">
						<p>
							Is your travel program delivering savings, efficiency and safety to your business and travelling workforce? Are you leveraging your
							travel data to negotiate better travel deals and positively influence booking behaviour? Do you have complete visibility and control
							over your travel budget?
						</p>

						<p className="pd-t-10">If not, it’s time to elevate your travel program with GITCO.</p>
					</div>
				</div>

				{/* SECTION 4 */}
				<div className="">
					{elevateContent.map((content, i) => (
						<Row key={i} className={i % 2 === 0 ? "pd-tb-50 section-content-im" : "pd-tb-50"}>
							<Col xs={12} md={6} className="pd-tb-20">
								<div className="pd-lr-20">
									<h2 className="section-title">{content.title}</h2>

									<p className="pd-t-20">{content.description}</p>

									<h6 className="section-title pd-tb-10">{content.subTitle}</h6>

									<ul className="points">
										{Object.keys(content.steps).map((key, index) =>
											index > 1 ? (
												<li key={index} className={`more-steps-${index}`}>
													{content.steps[key]}
												</li>
											) : (
												<li key={index}>{content.steps[key]}</li>
											)
										)}
									</ul>
								</div>
								{/* <button className="read" id={`more-btn-${i}`} onClick={() => readMore()}>
								Read More <i className="fas fa-angle-down"></i>
							</button> */}
							</Col>
							<Col xs={12} md={6} className="pd-tb-20">
								<img style={{ width: "100%" }} src={content.imgUrl} alt={content.name} />
							</Col>
						</Row>
					))}
				</div>
			</div>

			{/* CONTACT SECTION */}
			<div className="pd-tb-40">
				<ContactForm />
			</div>
		</>
	)
}

export default Home
