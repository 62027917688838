import React, { useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import visionContent from "./vision.json"
import "../home/Home.css"

function Vision() {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div className="main-wrapper">
			{/* SECTION 1 */}
			<div className="pd-tb-40">
				<Row>
					<Col md={4} className="vert-al">
						<h2 className="para-title">Our commitment to customers, employees, partners and shareholders</h2>
					</Col>
					<Col md={8}>
						<div>
							<h5 className="para-subtitle">
								GITCO is committed to delivering on the company’s vision and customer value proposition. Our board, leadership team and employees are
								all invested in creating and sustaining a workplace culture that supports equality of opportunity, empowerment, collaboration and
								recognition.
							</h5>

							<p>
								As a result, we continue to develop a highly engaged workforce and strategic thinking across every member of our team for the
								long-term benefit of our customers, partners and investors.
							</p>
						</div>
					</Col>
				</Row>
			</div>

			{/* SECTION 2 */}
			<div className="">
				{visionContent.map((content, i) => (
					<Row key={i} className={i % 2 === 0 ? "pd-tb-50 section-content-im" : "pd-tb-50"}>
						<Col xs={12} md={6} className="pd-tb-20">
							<div className="pd-lr-20">
								{/* <h2 className="section-title">{content.title}</h2> */}

								{content.description && <p className="pd-t-20">{content.description}</p>}

								{content.subTitle && <h6 className="section-title pd-tb-10">{content.subTitle}</h6>}

								{content.steps && (
									<ul className="points">
										{Object.keys(content.steps).map((key, index) =>
											index > 1 ? (
												<li key={index} className={`more-steps-${index}`}>
													{content.steps[key]}
												</li>
											) : (
												<li key={index}>{content.steps[key]}</li>
											)
										)}
									</ul>
								)}
							</div>
							{/* <button className="read" id={`more-btn-${i}`}>
								Read More <i className="fas fa-angle-down"></i>
							</button> */}
						</Col>
						<Col xs={12} md={6} className="pd-tb-20" style={{ alignSelf: "center" }}>
							<h2 className="section-title" style={{ textAlign: "center" }}>
								{content.title}
							</h2>
							{/* <img style={{ width: "100%" }} src={content.imgUrl} alt={content.name} /> */}
						</Col>
					</Row>
				))}
			</div>
		</div>
	)
}

export default Vision
