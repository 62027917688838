import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import OurStory from "./components/story/Story"
import Header from "./components/header/Header"
import Home from "./components/home/Home"
import Business from "./components/services/Business"
import EventTravel from "./components/services/EventTravel"
import LeisureTravel from "./components/services/LeisureTravel"
import SportTravel from "./components/services/SportTravel"
import Strategic from "./components/services/Strategic"
import Vision from "./components/vision/Vision"
import Contact from "./components/contact/Contact"
import Footer from "./components/footer/Footer"
import WhefHotelList from "./components/whef/hotel-listing/WhefHotelList"

const Routes = () => {
	return (
		<>
			<BrowserRouter>
				<Header />
				<Switch>
					<Route path="/" exact component={Home} />
					<Route path="/our-story" exact component={OurStory} />
					<Route path="/vision" exact component={Vision} />
					<Route path="/strategic-travel-management" exact component={Strategic} />
					<Route path="/small-business-solutions" exact component={Business} />
					<Route path="/event-travel" exact component={EventTravel} />
					<Route path="/sport-travel" exact component={SportTravel} />
					<Route path="/leisure-travel" exact component={LeisureTravel} />
					<Route path="/contact" exact component={Contact} />
					<Route path="/whef" exact component={WhefHotelList} />
				</Switch>
				<Footer />
			</BrowserRouter>
		</>
	)
}

export default Routes
