import React, { useState, useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import "../contact/Contact.css"
import "../home/Home.css"
import codes from "country-calling-code"
import emailjs from "emailjs-com"

const ContactForm = () => {
	const [fields, setFields] = useState({
		firstName: "",
		lastName: "",
		email: "",
		companyName: "",
		allCountries: [],
		country: "",
		countryCode: "",
		contactNumber: "",
		isCustomer: "",
		isSubscribed: "",
		comments: ""
	})

	const [loading, setLoading] = useState(false)
	const [isSubmit, setIsSubmit] = useState(false)

	const { allCountries, countryCode } = fields

	useEffect(() => {
		setFields({
			...fields,
			allCountries: codes
		})
	}, [])

	const handleChange = (name) => (event) => {
		if (name === "country") {
			const selectedCountry = allCountries.find((element) => element.country === event.target.value)

			setFields({
				...fields,
				country: selectedCountry.country,
				countryCode: selectedCountry.countryCodes[0]
			})
		} else if (name === "contactNumber") {
			setFields({
				...fields,
				contactNumber: countryCode + event.target.value
			})
		} else if (name === "isCustomer") {
			setFields({
				...fields,
				isSubscribed: event.target.value
			})
		} else if (name === "isSubscribed") {
			setFields({
				...fields,
				isSubscribed: event.target.value
			})
		} else {
			setFields({
				...fields,
				name: event.target.value
			})
		}
	}

	const submitContact = (event) => {
		event.preventDefault()
		setLoading(true)
		emailjs.sendForm("service_fb74hnd", "template_demo", event.target, "user_TNKKt1xBGiPdHvf90t68v").then(
			(result) => {
				console.log("result", result, result.text)
				document.getElementById("contact_form").reset()
				setIsSubmit(true)
				setLoading(false)
			},
			(error) => {
				console.log("error", error.text)
			}
		)
	}

	return (
		<div>
			<div className="contact-form-bg">
				<div className="contact-form-div">
					{isSubmit ? (
						<div style={{ textAlign: "center" }}>
							<img src="../assets/submit-success.jpeg" alt="Successfully submitted" style={{ width: "60%" }} />
						</div>
					) : (
						<>
							<h4 className="form-title">Take your first step towards better business travel</h4>
							<p className="form-subtitle">Simply submit your enquiry below and one of our travel experts will be in touch shortly.</p>

							{/* FORM */}
							<div className="">
								<form className="contact-form" id="contact_form" onSubmit={submitContact}>
									<Row>
										<Col md={6} sm={12}>
											<div className="pd-t-20">
												<p>
													<span className="req">* </span>First Name
												</p>
												<input name="firstName" onChange={handleChange("firstName")} type="text" className="form-input" required />
											</div>
										</Col>
										<Col md={6} sm={12}>
											<div className="pd-t-20">
												<p>Last Name</p>
												<input name="lastName" onChange={handleChange("lastName")} type="text" className="form-input" required />
											</div>
										</Col>
									</Row>

									<Row className="pd-t-20">
										<Col md={12} sm={12}>
											<p>
												<span className="req">* </span>Email
											</p>
											<input name="email" onChange={handleChange("email")} type="email" className="form-input" required />
										</Col>
									</Row>

									<Row className="pd-t-20">
										<Col md={12} sm={12}>
											<p>
												<span className="req">* </span>Company Name
											</p>
											<input name="companyName" onChange={handleChange("companyName")} type="text" className="form-input" required />
										</Col>
									</Row>

									<Row>
										<Col md={6} sm={12}>
											<div className="pd-t-20">
												<p>
													<span className="req">* </span>Select Region
												</p>
												<select name="country" onChange={handleChange("country")} className="form-input" required>
													<option value="">--Select--</option>
													{allCountries.map((item, i) => (
														<option key={i} value={item.country}>
															{item.country}
														</option>
													))}
												</select>
											</div>
										</Col>

										<Col md={6} sm={12}>
											<div className="pd-t-20">
												<p>
													<span className="req">* </span>Contact Number
												</p>
												<div className="inflex-justify">
													<input
														name="countryCode"
														type="text"
														value={countryCode}
														className="form-input"
														style={{ width: "25%", textAlign: "center" }}
														readOnly
													/>
													<input
														onChange={handleChange("contactNumber")}
														name="contactNumber"
														type="number"
														maxLength="10"
														className="form-input"
														style={{ width: "70%" }}
														required
													/>
												</div>
											</div>
										</Col>
									</Row>

									<Row className="pd-t-20">
										<Col md={12} sm={12}>
											<p>
												<span className="req">* </span>Is your company a GITCO customer?
											</p>
											<div>
												<div className="pd-t-10" style={{ display: "flex" }}>
													<input className="form-radio" type="radio" onChange={handleChange("isCustomer")} id="yes" name="isCustomer" value="Yes" />{" "}
													<label htmlFor="yes">&nbsp;Yes</label>
													{"  "}
												</div>
												<div className="pd-t-10" style={{ display: "flex" }}>
													<input className="form-radio" type="radio" onChange={handleChange("isCustomer")} id="no" name="isCustomer" value="No" /> 
													<label htmlFor="no">&nbsp;No</label>
												</div>
											</div>
										</Col>
									</Row>

									<Row className="pd-t-20">
										<Col md={12} sm={12}>
											<p>
												<span className="req">* </span>Comments
											</p>
											<textarea name="comments" onChange={handleChange("comments")} type="text" className="form-textarea" rows="5" required />
										</Col>
									</Row>

									<Row className="pd-t-30">
										<Col md={12} sm={12}>
											<div className="align-element">
												<input type="checkbox" name="isSubscribed" onChange={handleChange("isSubscribed")} className="form-checkbox" />
												{"  "}
												<label className="form-checkbox-text">YeS, I want to receive the latest travel news, insights and offers to my inbox!</label>
											</div>
										</Col>
									</Row>

									<Row className="pd-t-40">
										<Col>
											<button className="submit-form" type="submit">
												{loading ? (
													<>Submitting...</>
												) : (
													<>
														Submit <i className="fas fa-chevron-right"></i>
													</>
												)}
											</button>
										</Col>
									</Row>
								</form>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default ContactForm
