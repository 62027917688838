import React, { useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import "./Contact.css"
import "../home/Home.css"
import ContactForm from "../contactForm/ContactForm"
import MediaQuery from "react-responsive"

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div>
			<MediaQuery minDeviceWidth={426}>
				<img style={{ width: "100%", objectFit: "fill" }} src="../assets/contact/contact-1-desk.png" alt="Banner of Contact Us" />
			</MediaQuery>
			<MediaQuery maxDeviceWidth={425}>
				<img style={{ width: "100%", objectFit: "fill", height: "25em" }} src="../assets/home/img1.jpeg" alt="Banner of Contact Us" />
			</MediaQuery>

			{/* SECTION 1 */}
			<div className="main-wrapper">
				<div className="pd-tb-40">
					<Row>
						<Col md={4} className="">
							<h2 className="para-title">Contact GITCO</h2>
						</Col>
						<Col md={8}>
							<div>
								<h5 className="para-subtitle">Contact GITCO today for an obligation-free review of your travel program.</h5>

								<p>
									Our expert team are adept at identifying opportunities to reduce costs, increase efficiencies, drive policy compliance and
									technology adoption, and deliver a positive return on your travel investment.
								</p>
							</div>
						</Col>
					</Row>
				</div>
			</div>

			{/* CONTACT SECTION */}
			<div className="pd-tb-40">
				<ContactForm />
			</div>

			{/* ENQUIRIES */}
			<div className="pd-tb-40">
				<div className="enquiry">
					<h5 className="form-title ">For any queries</h5>
					<p className="pd-t-20">
						Email Us :{"  "}
						<span>
							<a href="mailto:reservations@thegitco.com">reservations@thegitco.com</a>
						</span>
					</p>
					<p>OR</p>
					<p>
						Call Us :{"  "}
						<span>
							<a href="tel:+918218623774">+918218623774</a>
						</span>
					</p>
				</div>
			</div>
		</div>
	)
}

export default Contact
