import React, { useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import "../home/Home.css"
import MediaQuery from "react-responsive"

function Business() {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div>
			<MediaQuery minDeviceWidth={426}>
				<img style={{ width: "100%", objectFit: "fill" }} src="../assets/business/1.png" alt="Banner of Business Solutions" />
			</MediaQuery>
			<MediaQuery maxDeviceWidth={425}>
				<img style={{ width: "100%", objectFit: "fill", height: "25em" }} src="../assets/business/mob-1.png" alt="Banner of Business Solutions" />
			</MediaQuery>

			<div className="main-wrapper">
				{/* SECTION 1 */}
				<div className="pd-tb-40">
					<Row>
						<Col md={4} className="">
							<h2 className="para-title">Small business travel solutions</h2>
						</Col>
						<Col md={8}>
							<div>
								<h5 className="para-subtitle">
									For smaller businesses, there’s never been a better time to drive greater value from your business travel spend. Access to corporate
									travel deals and intuitive travel technology is no longer reserved for the big end of town.
								</h5>

								<p>
									We offer a range of travel management solutions designed specifically for small and growing businesses. Our solutions drive savings
									to your travel budget while delivering efficiency and safety for your travelling workforce – leaving you to take care of your
									business.
								</p>
							</div>
						</Col>
					</Row>
				</div>

				{/* SECTION 2 */}
				{/* <div className="pd-tb-40">
					<Row>
						<Col md={6} sm={12}>
							<img style={{ width: "100%" }} src="../assets/home/img1.jpeg" alt="Banner of Business Solutions" />
						</Col>

						<Col md={6} sm={12}>
							<img style={{ width: "100%" }} src="../assets/home/img1.jpeg" alt="Banner of Business Solutions" />
						</Col>
					</Row>
				</div> */}

				{/* SECTION 3 */}
				<div className="pd-tb-50">
					<h2 className="para-title">Virtual Account Management</h2>
					<div className="pd-t-30">
						<p>
							We understand that businesses are busy, and every minute counts. You need a travel program that’s designed and optimised to drive
							savings and efficiency, but you don’t have time for strategy deep-dives or face-to-face reporting. That’s why we’ve designed a ‘virtual
							account management’ solution which offers{" "}
							<b>all the benefits of a customised travel program, delivered and supported via phone and online support for maximum convenience</b>.
						</p>

						<p className="pd-t-10">
							Your travel program’s development, delivery and ongoing support can be customised to incorporate any of GITCO’s optional technology and
							service solutions, and is supported by regular program reviews conducted annually, bi-annually or quarterly.
						</p>

						<p className="pd-t-10">
							Our small business travel teams are experts in providing guidance on ways to optimise your travel spend and loyalty benefits.
						</p>

						<MediaQuery minDeviceWidth={426}>
							<img style={{ width: "100%", objectFit: "fill" }} src="../assets/business/2.png" alt="Banner of Virtual Account Management" />
						</MediaQuery>
						<MediaQuery maxDeviceWidth={425}>
							<img
								style={{ width: "100%", objectFit: "fill", height: "25em" }}
								src="../assets/business/mob-2.png"
								alt="Banner of Virtual Account Management"
							/>
						</MediaQuery>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Business
