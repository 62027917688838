import React, { useEffect } from "react"
import { Row, Col } from "react-bootstrap"
import "../home/Home.css"
import MediaQuery from "react-responsive"

function LeisureTravel() {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div>
			<MediaQuery minDeviceWidth={426}>
				<img style={{ width: "100%", objectFit: "fill" }} src="../assets/leisure-travel/1.png" alt="Banner of Allure Travel by GITCO" />
			</MediaQuery>
			<MediaQuery maxDeviceWidth={425}>
				<img
					style={{ width: "100%", objectFit: "fill", height: "25em" }}
					src="../assets/leisure-travel/mob-1.png"
					alt="Banner of Allure Travel by GITCO"
				/>
			</MediaQuery>

			<div className="main-wrapper">
				{/* SECTION 1 */}
				<div className="pd-tb-40">
					<Row>
						<Col md={4} className="">
							<h2 className="para-title">Allure Travel by GITCO</h2>
						</Col>
						<Col md={8}>
							<div>
								<h5 className="para-subtitle">
									Allure Travel by GITCO specialises in providing our corporate customers with exceptional value holidays and exclusive vacation
									experiences.
								</h5>

								<p>
									From worldwide tours to weekend breaks, when you book with Allure Travel you’ll benefit from the same level of exemplary service and
									great value travel deals you’ve come to expect from GITCO.
								</p>
							</div>
						</Col>
					</Row>
				</div>

				{/* SECTION 2 */}
				{/* <div className="pd-tb-40">
					<Row>
						<Col md={6} sm={12}>
							<img style={{ width: "100%" }} src="../assets/home/img1.jpeg" alt="Allure Travel by GITCO" />
						</Col>

						<Col md={6} sm={12}>
							<img style={{ width: "100%" }} src="../assets/home/img1.jpeg" alt="Allure Travel by GITCO" />
						</Col>
					</Row>
				</div> */}

				{/* SECTION 3 */}
				<div className="pd-tb-50">
					<h2 className="para-title">Tailored holiday experiences for every style and budget</h2>
					<div className="pd-t-20">
						<p>
							The expert travel team at Allure Travel by CTM have every vacation travel style, budget and experience covered. Our customers enjoy
							exceptional value holiday experiences by leveraging CTM’s global supplier negotiations and the most competitively priced market
							specials.
						</p>

						<h6 className="fw-600 pd-tb-10">Allure Travel services include:</h6>
						<ul>
							<li>Flights, accommodation, car hire</li>
							<li>Overseas package holiday</li>
							<li>Luxury travel and cruising</li>
							<li>Escorted tours</li>
							<li>Tailor-made itineraries</li>
							<li>Travel insurance</li>
							<li>And much more!</li>
						</ul>

						<p className="pd-tb-10">
							Coupling first-hand travel experience with first-class industry knowledge, our passionate team will tailor an exceptional holiday
							experience to suit your specific needs.
						</p>

						{/* <MediaQuery minDeviceWidth={426}>
							<img style={{ width: "100%", objectFit: "fill" }} src="../assets/contact/contact-1-desk.png" alt="Allure Travel by GITCO" />
						</MediaQuery>
						<MediaQuery maxDeviceWidth={425}>
							<img style={{ width: "100%", objectFit: "fill", height: "25em" }} src="../assets/home/img1.jpeg" alt="Allure Travel by GITCO" />
						</MediaQuery> */}
					</div>
				</div>

				{/* SECTION 4 */}
				<div className="pd-tb-40">
					{/* <img
						style={{ width: "100%", objectFit: "fill", height: "26em" }}
						src="../assets/home/img1.jpeg"
						alt="Allure Travel by GITCO"
					/> */}

					<h2 className="para-title">Luxury escapes with Allure Travel</h2>
					<div>
						<h5 className="para-subtitle pd-t-20 fw-600">
							As an Allure Travel customer, you’ll also enjoy access to extraordinary travel upgrades and premium products that most travel agencies
							simply cannot provide.
						</h5>

						<p>
							We partner with more than 1000’s of the world’s best hotels, cruise lines, tour operators to deliver superior vacation experiences for
							our discerning customers. From bespoke travel itineraries, unique travel experiences, value-added extras, complimentary perks and VIP
							treatment, your Allure Travel holiday is guaranteed to deliver long-lasting memories.
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LeisureTravel
