import React, { useState } from "react"
import { Link } from "react-router-dom"
import "../header/Header.css"
import "./Footer.css"
import { Row, Col, Button, Modal } from "react-bootstrap"

const Footer = () => {
	const [showRPModal, setShowRPModal] = useState(false);
	const [showTCModal, setShowTCModal] = useState(false);
	const [showPPModal, setShowPPModal] = useState(false);
	const [showCUModal, setShowCUModal] = useState(false);

	return (
		<div className="main-wrapper ">
			<div className="" style={{ paddingBottom: "50px" }}>
				<Row>
					<div className="pd-tb-20 d-flex flex-wrap align-items-center justify-content-center">
						<Button className="bottom-cta" onClick={() => setShowRPModal(true)}>Refund Policy&nbsp; | &nbsp;</Button>
						<Button className="bottom-cta" onClick={() => setShowTCModal(true)}>Terms & Conditions&nbsp; | &nbsp;</Button>
						<Button className="bottom-cta" onClick={() => setShowPPModal(true)}>Privacy Policy&nbsp; | &nbsp;</Button>
						<Button className="bottom-cta" onClick={() => setShowCUModal(true)}>Contact Us</Button>
					</div>
				</Row>
				<Row>
					{/* <Col md={3} sm={12}>
						<div className="footer-logo">
							<img className="" src="../assets/logo-trans.png" alt="GITCO logo" />
						</div>
					</Col> */}
					<Col md={1}></Col>

					<Col md={10} sm={12} style={{ alignSelf: "center" }}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<li className="nav-item" style={{ listStyle: "none", width: "fit-content" }}>
								<Link className="nav-links" to="/strategic-travel-management">
									<p className="footer-links">
										<i className="fas fa-angle-right"></i>
										Services
									</p>
								</Link>
							</li>
							<li className="nav-item" style={{ listStyle: "none", width: "fit-content" }}>
								<Link className="nav-links" to="/our-story">
									<p className="footer-links">
										<i className="fas fa-angle-right"></i>
										About
									</p>
								</Link>
							</li>
							<li className="nav-item" style={{ listStyle: "none", width: "fit-content" }}>
								<Link className="nav-links" to="/contact">
									<p className="footer-links">
										<i className="fas fa-angle-right"></i>
										Contact
									</p>
								</Link>
							</li>
						</div>
					</Col>
					<Col md={1}></Col>
				</Row>

				<Row className="pd-t-20" style={{ textAlign: "center" }}>
					<Col className="pd-t-10" sm={12} md={8}>
						<p className="footer-link-offc">&copy; 2021 BigBash Projects Pvt. Ltd.</p>
					</Col>
					{/* <Col className="pd-t-10" sm={12} md={3}>
						<p className="footer-link-offc" style={{ textDecoration: "underline" }}>
							Privacy Policy
						</p>
					</Col>
					<Col className="pd-t-10" sm={12} md={3}>
						<p className="footer-link-offc" style={{ textDecoration: "underline" }}>
							Terms & Conditions
						</p>
					</Col> */}
					<Col className="pd-t-10" sm={12} md={4}>
						<div className="social-div">
							<div className="social-icon">
								<a href="https://www.linkedin.com/company/the-great-indian-travel-company/?viewAsMember=true" target="_blank" rel="noreferrer">
									<i className="fab fa-linkedin-in"></i>
								</a>
							</div>
							<div className="social-icon">
								<a href="https://www.instagram.com/the_gitco?igsh=czVlbXB6ZWE0dGlm&utm_source=qr" target="_blank" rel="noreferrer">
									<i className="fab fa-instagram"></i>
								</a>
							</div>
						</div>
					</Col>
				</Row>
			</div>

			{/* Refund Policy Modal */}
			<Modal show={showRPModal} onHide={()=>setShowRPModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title className="headline">Refund Policy</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>In case of a refund request, it will be processed within 7 to 14 working days.</p>
				</Modal.Body>
			</Modal>

			{/* Terms & Conditions Modal */}
			<Modal show={showTCModal} onHide={()=>setShowTCModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title className="headline">Terms & Conditions</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>1. Upon guest confirmation to proceed with the booking, the coordinator sends a payment link.</p>
					<p>2. Transaction fees will be borne by the guest.</p>
					<p>3. The booking is placed on hold for 24 hours from the time the payment link is sent.</p>
					<p>4. The payment link is valid for 24 hours only. The guest must make the payment within this timeline.</p>
					<p>5. The passenger processes the payment using the provided link within 24 hours.</p>
					<p>6. The booking coordinator verifies the payment receipt.</p>
				</Modal.Body>
			</Modal>

			{/* Privacy Policy Modal */}
			<Modal show={showPPModal} onHide={()=>setShowPPModal(false)} className="pd-15" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title className="headline">Privacy Policy</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						In particular, in order to secure payment transactions, Bigbash process personal data to determine the level of fraud risk associated with each transaction.
						On this occasion, Bigbash may use the Bigbash risk prevention service provider to refine their analysis. Depending on the results of the investigations carried out, 
						Bigbash may take security measures, in particular requesting the Customer to use a different reservation channel or an alternative payment method. 
						These measures will have the effect of suspending the execution of the reservation or, if the result of the analysis does not guarantee the safety of the order, of cancelling it. 
						Fraudulent use of a means of payment leading to payment default may result in the Customer being entered in the Bigbash incident file, which may lead Bigbash to block future payments or carry out additional checks.
					</p>
				</Modal.Body>
			</Modal>

			{/* Contact Us Modal */}
			<Modal show={showCUModal} onHide={()=>setShowCUModal(false)} className="pd-15" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
				<Modal.Header closeButton>
					<Modal.Title className="headline">Contact Us</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>L1 - Mr. Vikas Mishra : +91 73042-23812</p>
					<p>L1 - Mr. Rajeev Kumar : +91 97566-46127</p>
					<p>L2 - Mr. Sandeep Pandey : +91 88000-26648</p>
					<p>Email : whefmumbai@thegitco.com</p>
					<p>Website : www.thegitco.com</p>
					<p>Corporate Office Address : Bigbash Projects Private Limited, Plot No. 270, Udyog Vihar Phase 2, Sector 20, Gurugram, Haryana 122016</p>
					<p>Registered Office Address : Bigbash Projects Private Limited, B-703,Phase-II, Vardhman Dreams,Sr. No 226/227, Pune, Maharashtra, India, 411057</p>
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default Footer
