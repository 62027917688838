import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import "./Header.css"
import Navlink from "./navlinks.json"

function Header() {
	const [click, setClick] = useState(false)
	const [button, setButton] = useState(true)
	const [mobileRoute, setmobileRoute] = useState(false)
	const [activeRouteId, setActiveRouteId] = useState()

	const handleClick = () => setClick(!click)

	const closeMobileMenu = (id) => {
		setActiveRouteId(id)
		setClick(false)
	}

	const showButton = () => {
		if (window.innerWidth <= 860) {
			setmobileRoute(true)
			setButton(false)
		} else {
			setButton(true)
		}
	}

	useEffect(() => {
		showButton()
	}, [])

	window.addEventListener("resize", showButton)
	window.addEventListener("scroll", () => {
		var scrollIndex = document.documentElement.scrollTop
		var element1 = document.getElementById("brand-logo")
		var element2 = document.getElementById("navbar")

		if (scrollIndex > 60) {
			element1.classList.add("resize-logo")
			element2.classList.add("resize-div")
		} else {
			element1.classList.remove("resize-logo")
			element2.classList.remove("resize-div")
		}
	})

	return (
		<>
			<nav className="navbar" id="navbar">
				<div className="navbar-container">
					<Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
						<img id="brand-logo" className="brand-logo" src="../assets/logo-trans.png" alt="GITCO logo" />
					</Link>
					<div className="menu-icon" onClick={handleClick}>
						<i className={click ? "fas fa-times" : "fas fa-bars"} />
					</div>
					<ul className={click ? "nav-menu shadow active" : "nav-menu"}>
						{Navlink.map((route, j) => (
							<li key={route._id} className="nav-item">
								{mobileRoute ? (
									// mobile routes
									<div>
										{route.name === "Contact" ? (
											<Link
												className={route._id === activeRouteId ? "nav-links active-link" : "nav-links"}
												to={`/${route.routeLink}`}
												style={{ width: "100%" }}
												onClick={() => closeMobileMenu(route._id)}>
												<p id={route._id} style={{ padding: "0 0 10px 25px", textTransform: "uppercase" }}>
													{route.name}
												</p>
											</Link>
										) : (
											<p
												className={route._id === activeRouteId ? "nav-links active-link" : "nav-links"}
												id={route._id}
												style={
													j === 0
														? { margin: "0", width: "100%", padding: " 0 0 20px 25px", textTransform: "uppercase" }
														: { margin: "0", width: "100%", padding: " 0 0 0 25px", textTransform: "uppercase" }
												}>
												{route.name}
											</p>
										)}
										{route.subLinks &&
											route.subLinks.map(
												(link, i) =>
													i === 0 || i === 1 ? (
														<>
															<h5 className={i === 0 ? "nav-sublink" : "nav-sublink"}>{link.subHeading}</h5>
															<Link key={i} to={`/${link.subLink}`} style={{ textDecoration: "none" }} onClick={() => closeMobileMenu(link._id)}>
																<li
																	id={link._id}
																	className={link._id === activeRouteId ? "mobile-route-link pd-t-5 active-link" : "mobile-route-link pd-t-5"}>
																	{link.subLinkName}
																</li>
															</Link>
														</>
													) : (
														<>
															<Link key={i} to={`/${link.subLink}`} style={{ textDecoration: "none" }} onClick={() => closeMobileMenu(link._id)}>
																<li id={link._id} className="mobile-route-link pd-t-30">
																	<h5 className={link._id === activeRouteId ? "nav-sublink active-link" : "nav-sublink"}>{link.subLinkName}</h5>
																</li>
															</Link>
														</>
													)
												// <Link key={i} to={`/${link.subLink}`} style={{ textDecoration: "none" }} onClick={() => closeMobileMenu(link._id)}>
												// 	<li
												// 		id={link._id}
												// 		className={link._id === activeRouteId ? "mobile-route-link pd-tb-10 active-link" : "mobile-route-link pd-tb-10"}>
												// 		{link.subLinkName}
												// 	</li>
												// </Link>
											)}
									</div>
								) : (
									// desktop routes
									<div className="dropdown">
										{route.name === "Contact" ? (
											<Link to={`/${route.routeLink}`} style={{ textDecoration: "none" }} onClick={() => closeMobileMenu(route._id)}>
												<div className={route._id === activeRouteId ? "nav-links active-link" : "nav-links"} id={route._id}>
													<p style={{ margin: "5px 0 0 0", padding: "0.5rem 1rem", textTransform: "uppercase" }}>{route.name}</p>
												</div>
											</Link>
										) : (
											<>
												<div className="nav-links" id={route._id} onClick={() => closeMobileMenu(route._id)}>
													<p style={{ margin: "5px 0 0 0", padding: "0.5rem 1rem", textTransform: "uppercase" }}>{route.name}</p>
												</div>
												<div className="dropdown-content">
													{route.subLinks &&
														route.subLinks.map((link, i) =>
															i === 0 || i === 1 ? (
																<>
																	<h5 className="nav-sublink">{link.subHeading}</h5>
																	<li className="pd-tb-10">
																		<Link
																			key={i}
																			to={`/${link.subLink}`}
																			className={link._id === activeRouteId ? "corp-sublink active-link" : "corp-sublink"}
																			id={link._id}
																			style={{ marginLeft: "20px" }}
																			onClick={() => closeMobileMenu(link._id)}>
																			{link.subLinkName}
																		</Link>
																	</li>
																</>
															) : (
																<>
																	<Link
																		key={i}
																		to={`/${link.subLink}`}
																		className={link._id === activeRouteId ? "desktop-sublink active-link" : "desktop-sublink"}
																		id={link._id}
																		onClick={() => closeMobileMenu(link._id)}>
																		<h5 className="nav-sublink">{link.subLinkName}</h5>
																	</Link>
																</>
															)
														)}
												</div>
											</>
										)}
										{/* <div className="dropdown-content">
											{route.subLinks &&
												route.subLinks.map((link, i) =>
													i === 0 || i === 1 ? (
														<>
															<h5 className="nav-sublink">{link.subHeading}</h5>
															<li className={i === 0 ? "pd-tb-10" : ""}>
																<Link
																	key={i}
																	to={`/${link.subLink}`}
																	className={link._id === activeRouteId ? "corp-sublink active-link" : "corp-sublink"}
																	id={link._id}
																	style={{ marginLeft: "20px" }}
																	onClick={() => closeMobileMenu(link._id)}>
																	{link.subLinkName}
																</Link>
															</li>
														</>
													) : (
														<>
															<Link
																key={i}
																to={`/${link.subLink}`}
																className={link._id === activeRouteId ? "desktop-sublink active-link" : "desktop-sublink"}
																id={link._id}
																onClick={() => closeMobileMenu(link._id)}>
																<h5 className="nav-sublink">{link.subLinkName}</h5>
															</Link>
														</>
													)
												)}
										</div> */}
									</div>
								)}
							</li>
						))}
					</ul>
				</div>
			</nav>
		</>
	)
}

export default Header
